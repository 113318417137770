import { Accordion, AccordionPanel } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { AncillaryTypeWithAccordionInformation } from 'dto/ancillaryTypes';
import { ancillaryTypeSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import { TransSubtitle, TransSubtitleKeys } from 'i18n/trans/subtitle';
import { FC } from 'react';
import { AncillaryTypesLocalizations } from 'routes/ancillaryTypes/Accordion/AncillaryTypesLocalizations';
import { AncillaryTypesSubtypes } from 'routes/ancillaryTypes/Accordion/AncillaryTypesSubtypes';
import { useSelector } from 'store/utils';

interface AncillaryTypesAccordionProps {}

type TransKeyAndAncillaryTypeField = TransSubtitleKeys &
  keyof AncillaryTypeWithAccordionInformation;

const panels: Array<[TransKeyAndAncillaryTypeField, FC]> = [
  ['localizations', AncillaryTypesLocalizations],
  ['subtypes', AncillaryTypesSubtypes],
];

export const AncillaryTypesAccordion: FC<AncillaryTypesAccordionProps> = () => {
  const ancillaryType = useSelector(ancillaryTypeSelector);

  if (!ancillaryType) {
    return null;
  }

  return (
    <Accordion controlled={false}>
      {panels.map(([key, Component]) => (
        <AccordionPanel
          key={key}
          id={key}
          summary={
            <Stack direction="row" alignItems="baseline" sx={{ gap: '5px' }}>
              <Typography variant="subtitle">
                <TransSubtitle i18nKey={key} />
              </Typography>
              <Typography variant="body2">
                {`(${ancillaryType[key].length ?? 0})`}
              </Typography>
            </Stack>
          }
        >
          <Component />
        </AccordionPanel>
      ))}
    </Accordion>
  );
};
