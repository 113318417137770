import { FC, useMemo } from 'react';
import { SelectField, TextField, useForm } from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { InventoryClassField } from 'routes/products/productFields/InventoryClassField';
import { ClassificationGroup } from 'dto/classification';
import { OwnerField } from 'components/OwnerField';
import { AncillaryTypesField } from 'routes/products/productFields/AncillaryTypesField';
import { ProductForm } from 'routes/products/ProductForm';
import {
  Product,
  ProductAncillary,
  ProductAncillaryPayload,
  ProductObjectType,
} from 'dto/product';
import { useProductSelector } from 'features/product/productSelector';
import {
  useProductFormCommonFields,
  useProductFormInventoryClasses,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';

const isProductAncillary = (product?: Product): product is ProductAncillary =>
  Boolean(product) && product?.objectType === ProductObjectType.ANCILLARY;

interface AncillaryForm {}

export const AncillaryForm: FC<AncillaryForm> = () => {
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );
  const salesLevelOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_SALES_LEVEL
  );
  const product = useProductSelector(isProductAncillary);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();
  const inventoryClassIds = useProductFormInventoryClasses(product);

  const initialValues: Partial<ProductAncillaryPayload> = useMemo(
    () => ({
      ...commonFields,
      ...(product && {
        ...product,
        inventoryClassIds,
        salesLevelId: product.salesLevel?.id,
        ancillaryTypeId: product.ancillaryType?.id,
        ancillarySubtypeId: product.ancillarySubtype?.id,
      }),
    }),
    [commonFields, inventoryClassIds, product]
  );

  const formProps = useForm<ProductAncillaryPayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  return (
    <ProductForm formProps={formProps}>
      <Grid item xs={1}>
        <SelectField
          name="objectType"
          label={<TransField i18nKey="category" />}
          options={categoriesOptions}
          disabled
          required
        />
      </Grid>
      <Grid item xs={1}>
        <OwnerField disabled />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="description"
          label={<TransField i18nKey="description" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="code" label={<TransField i18nKey="code" />} required />
      </Grid>
      <Grid item xs={1}>
        <InventoryClassField />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          name="salesLevelId"
          label={<TransField i18nKey="salesLevel" />}
          options={salesLevelOptions}
          required
        />
      </Grid>
      <AncillaryTypesField />
    </ProductForm>
  );
};
