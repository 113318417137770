import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'store/utils';
import {
  getProduct,
  getProductCategoryMapping,
  setCurrentProduct,
} from 'features/product/productActions';
import { AdmissionForm } from 'routes/products/productForms/AdmissionForm';
import { useProductObjectType } from 'hooks/useProductFormUtilities';
import { AncillaryForm } from 'routes/products/productForms/AncillaryForm';
import { FeeForm } from 'routes/products/productForms/FeeForm';
import { ReservationForm } from 'routes/products/productForms/ReservationForm';
import { SupplementForm } from 'routes/products/productForms/SupplementForm';
import { TravelPassForm } from 'routes/products/productForms/TravelPassForm';
import { ProductObjectType } from 'dto/product';

interface ProductFormProps {}

export const ProductDetails: FC<ProductFormProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const objectType = useProductObjectType();
  const dispatch = useDispatch();
  const history = useHistory();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const isCreating = useMemo(
    () => action === 'create' && !Boolean(id),
    [action, id]
  );
  const forms = {
    [ProductObjectType.ADMISSION]: <AdmissionForm />,
    [ProductObjectType.ANCILLARY]: <AncillaryForm />,
    [ProductObjectType.FEE]: <FeeForm />,
    [ProductObjectType.RESERVATION]: <ReservationForm />,
    [ProductObjectType.SUPPLEMENT]: <SupplementForm />,
    [ProductObjectType.TRAVEL_PASS]: <TravelPassForm />,
  };

  useEffect(() => {
    dispatch(getProductCategoryMapping());

    if (isEditing && id) {
      dispatch(getProduct(id));
    }

    return () => {
      dispatch(setCurrentProduct(undefined));
    };
  }, [dispatch, id, isEditing]);

  useEffect(() => {
    if (isCreating && !objectType) {
      history.goBack();
    }
  }, [history, isCreating, objectType]);

  return objectType ? forms[objectType] : null;
};
