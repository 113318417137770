import type { FC } from 'react';
import { useMemo } from 'react';
import { SelectField, TextField, useForm } from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import { OwnerField } from 'components/OwnerField';
import { ProductForm } from 'routes/products/ProductForm';
import { useProductSelector } from 'features/product/productSelector';
import {
  Product,
  ProductObjectType,
  ProductSupplement,
  ProductSupplementPayload,
} from 'dto/product';
import {
  useProductFormCommonFields,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';

const isProductSupplement = (product?: Product): product is ProductSupplement =>
  Boolean(product) && product?.objectType === ProductObjectType.SUPPLEMENT;

interface SupplementFormProps {}

export const SupplementForm: FC<SupplementFormProps> = () => {
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );

  const product = useProductSelector(isProductSupplement);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();

  const initialValues: Partial<ProductSupplementPayload> = useMemo(
    () => ({
      ...commonFields,
      ...product,
    }),
    [commonFields, product]
  );

  const formProps = useForm<ProductSupplementPayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  return (
    <ProductForm formProps={formProps}>
      <Grid item xs={1}>
        <SelectField
          name="objectType"
          label={<TransField i18nKey="category" />}
          options={categoriesOptions}
          disabled
          required
        />
      </Grid>
      <Grid item xs={1}>
        <OwnerField disabled />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="description"
          label={<TransField i18nKey="description" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="code" label={<TransField i18nKey="code" />} required />
      </Grid>
    </ProductForm>
  );
};
