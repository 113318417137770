import {
  BusinessEntity,
  Carrier,
  ClassificationGroupItem,
  Country,
  STANDARD_CLASSIFICATION_GROUPS,
} from 'dto/classification';
import { createAsyncThunk } from 'store/utils';
import qs from 'qs';
import { setCurrentBusinessEntity } from 'features/common/commonActions';
import { api } from '@fleet/shared';

export const getClassificationGroups = createAsyncThunk<
  Array<ClassificationGroupItem>
>(
  'getClassificationGroups',
  async () =>
    (
      await api.get<{ items: Array<ClassificationGroupItem> }>(
        `/classifications/groups${qs.stringify(
          {
            ids: Object.values(STANDARD_CLASSIFICATION_GROUPS),
          },
          {
            addQueryPrefix: true,
            skipNulls: true,
            arrayFormat: 'comma',
          }
        )}`,
        {
          baseURL: process.env.REACT_APP_API_BD,
        }
      )
    ).data.items
);

export const getCountries = createAsyncThunk<Array<Country>>(
  'countries',
  async () =>
    (
      await api.get('/countries', {
        baseURL: process.env.REACT_APP_API_LM,
      })
    ).data.items
);

export const getCarriers = createAsyncThunk<Array<Carrier>>(
  'classification/carriers',
  async () =>
    (
      await api.get(
        `/organizations?${qs.stringify({
          RoleId: 'CONTACT_ROLE.BUS_COMPANY',
        })}`,
        {
          baseURL: process.env.REACT_APP_API_OM,
        }
      )
    ).data.organizations
);

export const getBusinessEntities = createAsyncThunk<Array<BusinessEntity>>(
  'classification/businessEntities',
  async (_, { getState, dispatch }) => {
    const businessEntities = (
      await api.get<{ items: Array<BusinessEntity> }>(
        `/users/business-entities`,
        {
          baseURL: process.env.REACT_APP_API_OM,
        }
      )
    ).data.items;

    const defaultBusinessEntity =
      businessEntities.find(({ isMain }) => isMain) || businessEntities[0];
    dispatch(
      setCurrentBusinessEntity(
        getState().common.currentBusinessEntityId || defaultBusinessEntity.id
      )
    );

    return businessEntities;
  }
);

export const getClassifications = createAsyncThunk(
  'getClassifications',
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(getCountries()),
      dispatch(getCarriers()),
      dispatch(getClassificationGroups()),
    ]);
  }
);
