import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  ancillaryTypeCreated: (
    <Trans
      i18nKey="alert.ancillaryTypeCreated"
      defaults="Ancillary type is created"
    />
  ),
  ancillaryTypeDeleted: (
    <Trans
      i18nKey="alert.ancillaryTypeDeleted"
      defaults="Ancillary type is deleted"
    />
  ),
  ancillaryTypeUpdated: (
    <Trans
      i18nKey="alert.ancillaryTypeUpdated"
      defaults="Ancillary type is updated"
    />
  ),
  coefficientsUpdate: (
    <Trans
      i18nKey="alert.coefficientsUpdate"
      defaults="Coefficients are updated"
    />
  ),
  inventoryParametersUpdated: (
    <Trans
      i18nKey="alert.inventoryParametersUpdated"
      defaults="Inventory model parameters updated"
    />
  ),
  passengerGroupUpdated: (
    <Trans
      i18nKey="alert.passengerGroupUpdated"
      defaults="Passenger group are updated"
    />
  ),
  productCategoryCreated: (
    <Trans
      i18nKey="alert.productCategoryCreated"
      defaults="Product category is created"
    />
  ),
  productCategorySaved: (
    <Trans
      i18nKey="alert.productCategorySaved"
      defaults="Product category is saved"
    />
  ),
  productCreated: (
    <Trans i18nKey="alert.productCreated" defaults="Product is created" />
  ),
  productDeleted: (
    <Trans i18nKey="alert.productDeleted" defaults="Product is deleted" />
  ),
  productUpdated: (
    <Trans i18nKey="alert.productUpdated" defaults="Product is updated" />
  ),
  salesChannelsAndRetailersUpdated: (
    <Trans
      i18nKey="alert.salesChannelsAndRetailersUpdated"
      defaults="Sales channels and retailers are updated"
    />
  ),
  tariffCopied: (
    <Trans i18nKey="alert.tariffCopied" defaults="Tariff is copied" />
  ),
  tariffCreated: (
    <Trans i18nKey="alert.tariffCreated" defaults="Tariff is created" />
  ),
  tariffUpdated: (
    <Trans i18nKey="alert.tariffUpdated" defaults="Tariff is updated" />
  ),
  timeDependantConditionsReset: (
    <Trans
      i18nKey="alert.timeDependantConditionsReset"
      defaults="Time dependent conditions are reset"
    />
  ),
  timeDependantConditionsUpdated: (
    <Trans
      i18nKey="alert.timeDependantConditionsUpdated"
      defaults="Time dependent conditions are updated"
    />
  ),
});
