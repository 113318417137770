import { createTrans, TransKeys } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  ancillaryTypesQty: (
    <Trans i18nKey="subTitle.ancillaryTypesQty" defaults="{{num}} types" />
  ),
  aftersalesRulesets: (
    <Trans
      i18nKey="subtitle.aftersalesRulesets"
      defaults="Aftersales Ruleset"
    />
  ),
  bundledProducts: (
    <Trans
      i18nKey="subtitle.bundledProducts"
      defaults="Included Products in Bundle"
    />
  ),
  coefficients: (
    <Trans i18nKey="subtitle.coefficients" defaults="Coefficients" />
  ),
  edit: <Trans i18nKey="subtitle.edit" defaults="Edit" />,
  fulfillmentRules: (
    <Trans i18nKey="subtitle.fulfillmentRules" defaults="Fulfillment rules" />
  ),
  itemsQty: <Trans i18nKey="subtitle.itemsQty" defaults="{{num}} items" />,
  inventoryModel: (
    <Trans i18nKey="subtitle.inventoryModel" defaults="Inventory model" />
  ),
  localizations: (
    <Trans i18nKey="subtitle.localizations" defaults="Localizations" />
  ),
  newAncillaryType: (
    <Trans i18nKey="subtitle.newAncillaryType" defaults="New Ancillary type" />
  ),
  newProduct: <Trans i18nKey="subtitle.newProduct" defaults="New product" />,
  newTariff: <Trans i18nKey="subtitle.newTariff" defaults="New Tariff" />,
  openUntilPriorToDeparture: (
    <Trans
      i18nKey="subtitle.openUntilPriorToDeparture"
      defaults="Open until prior to departure"
    />
  ),
  passengerGroup: (
    <Trans i18nKey="subtitle.passengerGroup" defaults="Passenger group" />
  ),
  productsQty: (
    <Trans i18nKey="subtitle.productsQty" defaults="{{num}} products" />
  ),
  salesChannelRules: (
    <Trans
      i18nKey="subtitle.salesChannelRules"
      defaults="Sales channel rules"
    />
  ),
  salesChannelsAndRetailers: (
    <Trans
      i18nKey="subtitle.salesChannelsAndRetailers"
      defaults="Sales channels and retailers"
    />
  ),
  salesDateFrom: (
    <Trans i18nKey="subtitle.salesDateFrom" defaults="Sales date from" />
  ),
  salesDateTo: (
    <Trans i18nKey="subtitle.salesDateTo" defaults="Sales date to" />
  ),
  salesPeriod: <Trans i18nKey="subtitle.salesPeriod" defaults="Sales period" />,
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  seatingRules: (
    <Trans i18nKey="subtitle.seatingRules" defaults="Seating rules" />
  ),
  selectedQty: (
    <Trans i18nKey="subtitle.selectedQty" defaults="{{num}} selected" />
  ),
  stopsQty: <Trans i18nKey="subtitle.stopsQty" defaults="{{num}} stops" />,
  subtypes: <Trans i18nKey="subtitle.subtypes" defaults="Subtypes" />,
  supplements: <Trans i18nKey="subtitle.supplements" defaults="Supplements" />,
  tariffQty: <Trans i18nKey="subtitle.tariffQty" defaults="{{num}} items" />,
  taxationRules: (
    <Trans i18nKey="subtitle.taxationRules" defaults="Taxation rules" />
  ),
  timeDependentConditions: (
    <Trans
      i18nKey="subtitle.timeDependentConditions"
      defaults="Time dependent conditions"
    />
  ),
  translations: (
    <Trans i18nKey="subtitle.translations" defaults="Translations" />
  ),
  travelBlackoutDates: (
    <Trans
      i18nKey="subtitle.travelBlackoutDates"
      defaults="Travel blackout dates"
    />
  ),
  travelDatePeriods: (
    <Trans
      i18nKey="subtitle.travelDatePeriods"
      defaults="Travel date periods"
    />
  ),
  travelDaysOfTheWeek: (
    <Trans
      i18nKey="subtitle.travelDaysOfTheWeek"
      defaults="Travel days of the week"
    />
  ),
  travelTimePeriods: (
    <Trans
      i18nKey="subtitle.travelTimePeriods"
      defaults="Travel time periods"
    />
  ),
});

export type TransSubtitleKeys = TransKeys<typeof TransSubtitle>;
