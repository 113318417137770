import type { FC } from 'react';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import {
  Icon,
  Modal,
  api,
  formSubmit,
  useDrawerForm,
  useForm,
} from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { currentProductSelector } from 'features/product/productSelector';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { getProducts } from 'features/product/productActions';
import { useModal } from '@fleet/shared/hooks';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';

interface ProductDeleteModalProps {}

export const ProductDeleteModal: FC<ProductDeleteModalProps> = () => {
  const product = useSelector(currentProductSelector);
  const { open: isOpen, onOpen, onClose } = useModal();
  const alert = useAlert();
  const dispatch = useDispatch();
  const drawerForm = useDrawerForm();

  const onSubmit = useCallback(
    () =>
      formSubmit(async () => {
        await api.delete(`/users/${product?.id}`);
        drawerForm.handleClose();
        alert.success(<TransAlert i18nKey="productDeleted" />);
        dispatch(getProducts());
      }),
    [alert, product?.id, dispatch, drawerForm]
  );

  const { handleSubmit, submitting } = useForm({
    onSubmit,
    subscription: { submitting: true },
  });

  if (!product) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={<Icon name="trash" size={16} />}
        size="small"
        color="error"
        onClick={onOpen}
        disabled
      >
        <TransButton i18nKey="delete" />
      </Button>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={<TransModal i18nKey="deleteProduct" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              startIcon={<Icon name="trash" size={16} />}
              variant="contained"
              color="error"
              disabled={submitting}
            >
              <TransButton i18nKey="confirm" />
            </Button>
          </form>
        }
        maxWidth="xs"
      />
    </>
  );
};
