import { FC, useCallback, useEffect, useState } from 'react';
import {
  makeClassificationOptions,
  ModalFieldArray,
  SelectField,
} from '@fleet/shared';
import { Grid } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { noop } from 'lodash';
import { Option } from '@fleet/shared/dto/option';
import {
  fetchTariffPassengerTypeDiscounts,
  fetchTariffPassengerTypes,
} from 'features/tariff/tariffService';
import { TransField } from 'i18n/trans/field';
import { renderToString } from 'react-dom/server';

type PassengerTypeConfiguration = { id: number; discountId?: string };

export const PassengerType: FC<{ ownerId: number }> = ({ ownerId }) => {
  const [passengerTypeOptions, setPassengerTypeOptions] = useState<
    Array<Option<number>>
  >([]);
  const [passengerTypeDiscountOptions, setPassengerTypeDiscountOptions] =
    useState<Array<Option<string | number>>>([]);

  const fetchPassengerTypes = useCallback(
    async (ownerId: number) =>
      setPassengerTypeOptions(
        makeClassificationOptions(await fetchTariffPassengerTypes(ownerId))
      ),
    []
  );

  const fetchPassengerTypeDiscounts = useCallback(
    async (ownerId: number) =>
      setPassengerTypeDiscountOptions(
        makeClassificationOptions(
          await fetchTariffPassengerTypeDiscounts(ownerId)
        )
      ),
    []
  );

  useEffect(() => {
    fetchPassengerTypes(ownerId);
    fetchPassengerTypeDiscounts(ownerId);
  }, [fetchPassengerTypeDiscounts, fetchPassengerTypes, ownerId]);

  const validateRow = (rows?: Array<PassengerTypeConfiguration>) => {
    if (rows) {
      if (rows.some((row) => Object.keys(row).length === 0)) {
        return renderToString(
          <TransField i18nKey="passengerTypesFieldCantBeEmpty" />
        );
      }
    }
    return null;
  };

  const getAccessor = useCallback(
    ({ id, discountId }: PassengerTypeConfiguration) => {
      const passengerType = passengerTypeOptions.find(
        ({ value }) => value === id
      );
      const discount = passengerTypeDiscountOptions.find(
        ({ value }) => value === discountId
      );
      return `${passengerType?.label}${discount ? ' - ' + discount.label : ''}`;
    },
    [passengerTypeDiscountOptions, passengerTypeOptions]
  );

  return (
    <ModalFieldArray<PassengerTypeConfiguration>
      name="passengerTypes"
      label={<TransField i18nKey="passengerTypes" />}
      accessor={getAccessor}
      validate={validateRow}
      render={({ name, removeButton }) => ({
        canRemove: false,
        field: (
          <Grid container spacing={2}>
            <Grid item sx={{ flex: 1 }}>
              <SelectField name={`${name}.id`} options={passengerTypeOptions} />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <SelectField
                name={`${name}.discountId`}
                options={passengerTypeDiscountOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item>{removeButton}</Grid>
          </Grid>
        ),
      })}
      modalProps={{
        actionButton: {
          label: <TransButton i18nKey="confirm" />,
          icon: 'check',
          onClick: noop,
        },
      }}
    />
  );
};
