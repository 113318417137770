import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransTableHead } from 'i18n/trans/table';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { TaxationRule } from 'dto/taxationRule';
import {
  FormProvider,
  Table,
  TableColumns,
  api,
  useForm,
  useFormTableControls,
} from '@fleet/shared';
import {
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import { Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { currentProductSelector } from 'features/product/productSelector';
import { getProduct } from 'features/product/productActions';

const IS_DELETE_FUNCTIONALITY_HIDDEN = true; //This functionality is't fully implemented in the BE yet

interface TaxationRulesProps {}

export const TaxationRules: FC<TaxationRulesProps> = () => {
  const product = useSelector(currentProductSelector);
  const dispatch = useDispatch();
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const taxTypeOptions = useClassificationOptions(ClassificationGroup.TAX_TYPE);
  const data = product!.taxationRules;

  const { form } = useForm<{ rows: Array<TaxationRule> }>({
    initialValues: {
      rows: data,
    },
  });

  const columns: TableColumns<TaxationRule> = useMemo(
    () => [
      {
        accessor: ({ taxType }) => taxType?.id,
        id: 'taxType.id',
        type: 'select',
        Header: <TransTableHead i18nKey="taxType" />,
        editableProps: {
          options: taxTypeOptions,
        },
      },
      {
        accessor: 'percentage',
        type: 'text',
        Header: <TransTableHead i18nKey="tax" />,
        editableProps: {
          type: 'number',
          positive: true,
        },
      },
      {
        accessor: ({ country }) => country?.id,
        id: 'country.id',
        type: 'select',
        Header: <TransTableHead i18nKey="country" />,
        editableProps: {
          options: countryOptions,
        },
      },
    ],
    [countryOptions, taxTypeOptions]
  );

  const handleRowUpdate = useCallback(
    async ({ id, taxType, country, percentage }) => {
      const data = {
        taxTypeId: taxType.id,
        countryId: country.id,
        percentage: !!Number(percentage)
          ? Number(percentage) / 100
          : percentage,
      };

      if (id) {
        await api.put(`/taxation-rules/${id}`, data);
      } else {
        await api.post(`/products/${product!.id}/taxation-rules`, data);
      }

      dispatch(getProduct(product!.id));
    },
    [dispatch, product]
  );

  const table = useFormTable<TaxationRule>(
    {
      data,
      columns,
      form,
      initialState: { pageSize: 20 },
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const handleRowsDeleted = useCallback(
    async (values: Array<TaxationRule>) => {
      await Promise.all(
        values.map(({ id }) => api.delete(`/taxation-rules/${id}`))
      );
      dispatch(getProduct(product!.id));
    },
    [dispatch, product]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsDeleted,
  });

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{ mb: 1, mt: 1 }}
      >
        {IS_DELETE_FUNCTIONALITY_HIDDEN ? null : (
          <Button
            variant="text"
            color="error"
            onClick={removeSelectedRows}
            startIcon={<Icon name="trash" size={20} />}
            disabled={Object.keys(table.state.selectedRowIds).length === 0}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
        )}
        <Button startIcon={<Icon name="plus" />} onClick={addRow}>
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        table={table}
        getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
      />
    </FormProvider>
  );
};
