export const decimalToNumber = (value?: number) => {
  if (!value) {
    return value;
  }

  return parseFloat((value * 100).toPrecision(3));
};

export const numberToDecimal = (value?: number) => {
  if (!value) {
    return value;
  }

  return Number(value) / 100;
};
