import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getProducts } from 'features/product/productActions';
import {
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  useRowActive,
} from '@fleet/shared';
import { Product } from 'dto/product';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, usePagination, useTable } from 'react-table';
import { ProductsSearchForm } from 'routes/products/ProductsSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  productsFilterSelector,
  productsSelector,
} from 'features/product/productSelector';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { productsTableLoadingSelector } from 'features/loading/loadingSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';

interface ProductsTableProps {}

export const ProductsTable: FC<ProductsTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const product = useSelector(productsSelector);
  const owners = useSelector(businessEntitiesSelector);
  const filter = useSelector(productsFilterSelector);
  const dispatch = useDispatch();
  const loading = useSelector(productsTableLoadingSelector);
  const data = useMemo(() => product?.items ?? [], [product]);
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );

  const categoriesMap = useMemo(
    () => new Map(categoriesOptions.map(({ value, label }) => [value, label])),
    [categoriesOptions]
  );

  const link = useCallback(
    (row: Row<Product>) => `/products/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<Product>>(
    () => [
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ value, row }: { value: string; row: Row<Product> }) => (
          <Link to={link(row)}>{value}</Link>
        ),
      },
      {
        accessor: 'ownerId',
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ value }) =>
          owners.find((owner) => owner.id === value)?.name ?? '—',
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        accessor: 'categoryClassificationId',
        Header: <TransTableHead i18nKey="category" />,
        Cell: ({ value }) => categoriesMap.get(value) ?? '',
      },
    ],
    [categoriesMap, link, owners]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (product) {
        const { limit = pageSize, offset } = product;
        return offset / limit;
      }
      return 0;
    },
    [product]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getProducts({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const table = useTable<Product>(
    {
      data,
      columns,
      pageCount: -1,
      total: product?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      getRowId,
    },
    usePagination,
    useRowActive
  );

  const { activeFlatRow, resetRowActive } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  return (
    <>
      <ProductsSearchForm />
      <Divider />
      <Loadable loading={loading}>
        <SearchResult results={data.length} loading={loading}>
          <Table
            caption={
              <CardContent sx={{ padding: '16px 24px' }}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="baseline" spacing={1}>
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="productsQty"
                        values={{ num: product?.totalCount }}
                      />
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            }
            table={table}
          />
        </SearchResult>
      </Loadable>
    </>
  );
};
