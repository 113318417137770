import { FC, useCallback } from 'react';
import { Grid, Stack } from '@mui/material';
import {
  Button,
  FormProvider,
  SelectField,
  TextField,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { StopSearchFilter } from 'dto/stop';
import { Icon } from '@fleet/shared/mui';

interface StopSearchFormProps {
  onFilterSubmit: (filter: StopSearchFilter) => void;
}

export const StopSearchForm: FC<StopSearchFormProps> = ({ onFilterSubmit }) => {
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);

  const onSubmit = useCallback(
    (values: StopSearchFilter) => {
      return formSubmit(async () => onFilterSubmit(values));
    },
    [onFilterSubmit]
  );

  const { form, handleSubmit } = useForm<StopSearchFilter>({
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={3} spacing={2} alignItems="flex-end">
          <Grid item xs={1}>
            <TextField name="Name" label={<TransField i18nKey="name" />} />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="countryId"
              label={<TransField i18nKey="country" />}
              options={countryOptions}
              showEmptyOption
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="countyName"
              label={<TransField i18nKey="county" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField name="cityName" label={<TransField i18nKey="city" />} />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="streetAddress"
              label={<TransField i18nKey="streetHouseNumber" />}
            />
          </Grid>

          <Grid item xs="auto" sx={{ ml: 'auto' }} alignItems="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="text"
                onClick={handleReset}
              >
                <TransButton i18nKey="resetFilters" />
              </Button>

              <Button
                variant="contained"
                type="submit"
                startIcon={<Icon name="search" size={20} />}
              >
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
