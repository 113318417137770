import { Button } from '@mui/material';
import {
  Icon,
  Loadable,
  Modal,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { Product } from 'dto/product';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TransTableHead } from 'i18n/trans/table';
import { useRowSelect, useTable } from 'react-table';
import { TransModal } from 'i18n/trans/modal';
import { useDispatch, useSelector } from 'store/utils';
import { updateBundledProducts } from 'features/product/productActions';
import {
  productCategoriesSelector,
  currentProductSelector,
} from 'features/product/productSelector';
import { makeStyles } from '@mui/styles';
import { fetchProducts } from 'features/product/productService';

interface AddModalProps {
  open: boolean;
  onClose: () => void;
}
const useStyles = makeStyles(
  () => ({
    table: {
      maxHeight: '60vh',
    },
  }),
  { name: 'AddModal' }
);

export const AddModal: FC<AddModalProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const currentProduct = useSelector(currentProductSelector)!;
  const productCategories = useSelector(productCategoriesSelector);
  const getProducts = useCallback(async () => {
    setLoading(true);
    const products: Array<Product> = (
      await fetchProducts({
        excludeBundles: true,
        ownerId: currentProduct.ownerId,
        limit: 9999,
      })
    ).items;
    setProducts(products.filter(({ id }) => id !== currentProduct.id));
    setLoading(false);
  }, [currentProduct]);

  useEffect(() => {
    if (open) {
      getProducts();
    }
  }, [getProducts, open]);
  const columns: TableColumns<Product> = useMemo(
    () => [
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="productName" />,
      },
      {
        id: 'category',
        accessor: ({ categoryId }) =>
          productCategories.find(({ id }) => id === categoryId)?.category.name,
        Header: <TransTableHead i18nKey="category" />,
      },
    ],
    [productCategories]
  );
  const initialSelectedRowIds = useMemo(() => {
    return (
      currentProduct.bundledProducts?.reduce(
        (selectedIds, currentProduct) => ({
          ...selectedIds,
          [currentProduct.id]: true,
        }),
        {}
      ) ?? {}
    );
  }, [currentProduct.bundledProducts]);
  const getRowId = useCallback((row: Product) => row.id, []);
  const table = useTable(
    {
      data: products,
      initialState: {
        selectedRowIds: initialSelectedRowIds,
      },
      columns,
      getRowId,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );
  const {
    state: { selectedRowIds },
  } = table;

  const handleAddProducts = useCallback(async () => {
    await dispatch(
      updateBundledProducts({
        id: currentProduct.id,
        productIds: Object.keys(selectedRowIds),
      })
    ).unwrap();
    onClose();
  }, [dispatch, onClose, currentProduct.id, selectedRowIds]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<TransModal i18nKey="addProductsToBundle" />}
      actionButton={
        <Button
          startIcon={<Icon name="add" size={16} />}
          variant="contained"
          onClick={handleAddProducts}
        >
          <TransButton i18nKey="addProducts" />
        </Button>
      }
      maxWidth="md"
    >
      <Loadable loading={loading}>
        <Table
          classes={{
            table: classes.table,
          }}
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          table={table}
        />
      </Loadable>
    </Modal>
  );
};
