import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import {
  Tariff,
  TariffSearchFilter,
  TariffSupplement,
  TimeDependentConditions,
  PassengerGroup,
  PriceRules,
  SalesRules,
  InventoryParameters,
} from 'dto/tariff';
import {
  getTariffs,
  getTariffSupplements,
  getTariffTimeDependentConditions,
  getTariffPassengerGroup,
  setTariff,
  setTariffFilter,
  getTariffPriceRules,
  getTariffSalesRules,
  getTariffInventoryParameters,
  clearTariffs,
} from 'features/tariff/tariffActions';

interface TariffState {
  search?: Pagination<Tariff>;
  filter: Partial<TariffSearchFilter>;
  current?: Tariff;
  timeDependentConditions: Array<TimeDependentConditions>;
  supplements: Array<TariffSupplement>;
  passengerGroup: Array<PassengerGroup>;
  priceRules?: PriceRules;
  salesRules?: SalesRules;
  inventoryParameters?: InventoryParameters;
}

const initialState: TariffState = {
  filter: {},
  timeDependentConditions: [],
  supplements: [],
  passengerGroup: [],
};

export const tariffReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTariffFilter, (state, action) => {
      state.filter = action.payload ?? {};
    })
    .addCase(setTariff, (state, action) => {
      state.current = action.payload;
    })
    .addCase(clearTariffs, (state) => {
      state.search = undefined;
    })
    .addCase(getTariffs.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(getTariffs.rejected, (state) => {
      state.search = undefined;
    })
    .addCase(getTariffTimeDependentConditions.fulfilled, (state, action) => {
      state.timeDependentConditions = action.payload;
    })
    .addCase(getTariffSupplements.fulfilled, (state, action) => {
      state.supplements = action.payload;
    })
    .addCase(getTariffPassengerGroup.fulfilled, (state, action) => {
      state.passengerGroup = action.payload;
    })
    .addCase(getTariffPriceRules.fulfilled, (state, action) => {
      state.priceRules = action.payload;
    })
    .addCase(getTariffSalesRules.fulfilled, (state, action) => {
      state.salesRules = action.payload;
    })
    .addCase(getTariffInventoryParameters.fulfilled, (state, action) => {
      state.inventoryParameters = action.payload;
    });
});
