import { FC, useMemo } from 'react';
import { SelectField, TextField, useForm } from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { InventoryClassField } from 'routes/products/productFields/InventoryClassField';
import { ClassificationGroup } from 'dto/classification';
import { OwnerField } from 'components/OwnerField';
import { ProductForm } from 'routes/products/ProductForm';
import { useProductSelector } from 'features/product/productSelector';
import {
  Product,
  ProductObjectType,
  ProductReservation,
  ProductReservationPayload,
} from 'dto/product';
import {
  useProductFormCommonFields,
  useProductFormInventoryClasses,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';

const isProductReservation = (
  product?: Product
): product is ProductReservation =>
  Boolean(product) && product?.objectType === ProductObjectType.RESERVATION;

interface ReservationFormProps {}

export const ReservationForm: FC<ReservationFormProps> = () => {
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );

  const product = useProductSelector(isProductReservation);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();
  const inventoryClassIds = useProductFormInventoryClasses(product);

  const initialValues: Partial<ProductReservationPayload> = useMemo(
    () => ({
      ...commonFields,
      ...(product && {
        ...product,
        inventoryClassIds,
      }),
    }),
    [commonFields, inventoryClassIds, product]
  );

  const formProps = useForm<ProductReservationPayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  return (
    <ProductForm formProps={formProps}>
      <Grid item xs={1}>
        <SelectField
          name="objectType"
          label={<TransField i18nKey="category" />}
          options={categoriesOptions}
          disabled
          required
        />
      </Grid>
      <Grid item xs={1}>
        <OwnerField disabled />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="description"
          label={<TransField i18nKey="description" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="code" label={<TransField i18nKey="code" />} required />
      </Grid>
      <Grid item xs={1}>
        <InventoryClassField />
      </Grid>
    </ProductForm>
  );
};
