import { Classifier } from '@fleet/shared/dto/classifier';

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  AFTERSALES_ACTIVITY = 'AFTERSALES_ACTIVITY',
  AFTERSALES_ACTIVITY_TIME_RESTRICTION = 'AFTERSALES_ACTIVITY_TIME_RESTRICTION',
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  DISTANCE_FARE_IMPLEMENTATION_METHOD = 'DISTANCE_FARE_IMPLEMENTATION_METHOD',
  FARE_CATEGORY = 'FARE_CATEGORY',
  FARE_TYPE = 'FARE_TYPE',
  JOURNEY_DIRECTION = 'JOURNEY_DIRECTION',
  PRICING_DISTANCE_TYPE = 'PRICING_DISTANCE_TYPE',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT_SALES_LEVEL = 'PRODUCT_SALES_LEVEL',
  REDUCED_MOBILITY_TYPE = 'REDUCED_MOBILITY_TYPE',
  RESERVATION_OPTION = 'RESERVATION_OPTION',
  SALES_CHANNEL = 'SALES_CHANNEL',
  SUPPLEMENT_AREA_RULE = 'SUPPLEMENT_AREA_RULE',
  TAX_TYPE = 'TAX_TYPE',
  TRAVEL_PERIOD_TYPE = 'TRAVEL_PERIOD_TYPE',
  TRAVEL_SERVICE_ACTIVATION_EVENT = 'TRAVEL_SERVICE_ACTIVATION_EVENT',
  TRAVEL_SERVICE_MEASUREMENT_UNIT = 'TRAVEL_SERVICE_MEASUREMENT_UNIT',
  TRAVEL_SERVICE_TYPE = 'TRAVEL_SERVICE_TYPE',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  CARRIER = 'CARRIER',
  COUNTRY = 'COUNTRY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.AFTERSALES_ACTIVITY,
  ClassificationGroup.AFTERSALES_ACTIVITY_TIME_RESTRICTION,
  ClassificationGroup.CULTURE,
  ClassificationGroup.CURRENCY,
  ClassificationGroup.DISTANCE_FARE_IMPLEMENTATION_METHOD,
  ClassificationGroup.FARE_CATEGORY,
  ClassificationGroup.FARE_TYPE,
  ClassificationGroup.JOURNEY_DIRECTION,
  ClassificationGroup.PRICING_DISTANCE_TYPE,
  ClassificationGroup.PRODUCT_CATEGORY,
  ClassificationGroup.PRODUCT_SALES_LEVEL,
  ClassificationGroup.REDUCED_MOBILITY_TYPE,
  ClassificationGroup.RESERVATION_OPTION,
  ClassificationGroup.SALES_CHANNEL,
  ClassificationGroup.SUPPLEMENT_AREA_RULE,
  ClassificationGroup.TAX_TYPE,
  ClassificationGroup.TRAVEL_PERIOD_TYPE,
  ClassificationGroup.TRAVEL_SERVICE_ACTIVATION_EVENT,
  ClassificationGroup.TRAVEL_SERVICE_MEASUREMENT_UNIT,
  ClassificationGroup.TRAVEL_SERVICE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface Country extends Classifier {
  areaPhoneCode?: string;
  code?: string;
  culture?: string;
  currency?: string;
  unixTimeZone?: string;
  vatRate?: string;
}

export interface Country extends Classifier {
  culture?: string;
  currency?: string;
  vatRate?: string;
  code?: string;
  areaPhoneCode?: string;
  unixTimeZone?: string;
}

export interface Carrier extends Classifier<number> {
  code: string;
  currency: string;
  email: string;
  fax: string | null;
  operatingTimezone: string;
  phone: string;
  registrationCode: string;
  vatRegistrationCode: string | null;
  webpage: string | null;
}

export interface BusinessEntity extends Classifier<number> {
  isMain: boolean;
  contactType: string;
}
