import {
  Button,
  ConfirmDeleteModal,
  Icon,
  SearchResult,
  Table,
  TableColumns,
  TableDragDropProps,
  useDraggable,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowActive,
} from '@fleet/shared';
import { CardContent, Link as MuiLink, Stack, Typography } from '@mui/material';
import { AncillaryType } from 'dto/ancillaryTypes';
import {
  deleteAncillaryTypes,
  reorderAncillaryTypes,
} from 'features/ancillaryTypes/ancillaryTypesActions';
import { ancillaryTypesSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import { ancillaryTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';

interface AncillaryTypesTableProps {}

export const AncillaryTypesTable: FC<AncillaryTypesTableProps> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const alert = useAlert();

  const { open: isOpen, onOpen, onClose } = useModal();
  const ancillaryTypes = useSelector(ancillaryTypesSelector);
  const loading = useSelector(ancillaryTypesLoadingSelector);
  const data = useMemo(() => ancillaryTypes, [ancillaryTypes]);

  const link = useCallback(
    (row: Row<AncillaryType>) => `/ancillary-types/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<AncillaryType>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <MuiLink underline="none" component={Link} to={link(row)}>
            {value}
          </MuiLink>
        ),
      },
      {
        id: 'owner.name',
        accessor: ({ owner }) => owner.name,
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="order" />,
      },
      {
        id: 'localizations',
        accessor: ({ localizations }) => localizations.length || '-',
        Header: <TransTableHead i18nKey="localizations" />,
      },
    ],
    [link]
  );

  const initialState = useMemo(() => ({ pageSize: 10 }), []);
  const table = useTable<AncillaryType>(
    {
      data,
      columns,
      initialState,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useDraggable
  );

  const { activeFlatRow, resetRowActive, selectedFlatRows } = table;

  useEffect(() => {
    if (activeFlatRow) {
      history.replace(link(activeFlatRow));
    }
  }, [activeFlatRow, history, link]);

  useEffect(() => {
    if (!id) {
      resetRowActive();
    }
  }, [id, resetRowActive]);

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteAncillaryTypes(selectedFlatRows.map(({ original }) => original.id))
    ).unwrap();

    onClose();
    alert.success(<TransAlert i18nKey="ancillaryTypeDeleted" />);
  }, [dispatch, selectedFlatRows, onClose, alert]);

  const handleDragEnd = useCallback<
    TableDragDropProps<AncillaryType>['onDragEnd']
  >(
    ({ data }) => {
      dispatch(reorderAncillaryTypes(data));
    },
    [dispatch]
  );

  return (
    <SearchResult
      results={!!data.length}
      loading={loading}
      i18nKey="noDataAdded"
    >
      <Table
        caption={
          <>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 2 }}
                >
                  <TransSubtitle
                    i18nKey="ancillaryTypesQty"
                    values={{
                      num: ancillaryTypes.length,
                    }}
                  />
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" color="text.secondary">
                    <TransSubtitle
                      i18nKey="selectedQty"
                      values={{
                        num: table.selectedFlatRows.length,
                      }}
                    />
                  </Typography>
                  <Button
                    variant="text"
                    startIcon={<Icon name="delete" />}
                    onClick={onOpen}
                    disabled={table.selectedFlatRows.length === 0}
                    color="error"
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </>
        }
        table={table}
        draggable
        onDragEnd={handleDragEnd}
      />
      <ConfirmDeleteModal
        handleDelete={handleRowsRemove}
        title={<TransModal i18nKey="deleteAncillaryTypes" />}
        description={<TransModal i18nKey="ancillaryTypesDeletionDescription" />}
        isOpen={isOpen}
        onClose={onClose}
      />
    </SearchResult>
  );
};
