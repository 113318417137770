import {
  Product,
  ProductObjectType,
  ProductWithInventoryClass,
} from 'dto/product';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import {
  productCategoriesSelector,
  currentProductSelector,
  productsFilterSelector,
} from 'features/product/productSelector';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  createProduct,
  getProducts,
  updateProduct,
} from 'features/product/productActions';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';

export const useProductFormCommonFields = <T extends Product>(
  product?: T
): {
  objectType?: T['objectType'];
  categoryId?: string;
  ownerId?: number;
} => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const owner = businessEntities.find(
    ({ id }) => id === (product?.ownerId ?? currentBusinessEntityId)
  );

  const objectType = useProductObjectType();
  const productCategories = useSelector(productCategoriesSelector);
  const categoryId = productCategories.find(
    ({ category }) => category.id === objectType
  )?.id;

  return useMemo(
    () => ({
      objectType,
      categoryId,
      ownerId: owner?.id,
    }),
    [categoryId, objectType, owner?.id]
  );
};

export const useProductFormOnSubmit = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const filter = useSelector(productsFilterSelector);
  const history = useHistory();

  return useCallback(
    async ({ inventoryClassIds, ...values }) => {
      const payload = {
        ...values,
        inventoryClassIds:
          typeof inventoryClassIds === 'string'
            ? [inventoryClassIds]
            : inventoryClassIds,
      };

      const data = await dispatch(
        (values.id ? updateProduct : createProduct)(payload)
      ).unwrap();

      if (values.id) {
        alert.success(<TransAlert i18nKey="productUpdated" />);
      } else {
        alert.success(<TransAlert i18nKey="productCreated" />);
        history.replace({
          pathname: `/products/edit/${data.id}`,
          state: {
            objectType: data.objectType,
          },
        });
      }

      await dispatch(getProducts(filter));
    },
    [alert, dispatch, filter, history]
  );
};

export const useProductObjectType = () => {
  const { state } = useLocation<{
    objectType?: ProductObjectType;
  }>();
  const product = useSelector(currentProductSelector);
  const { id } = useParams<{ action: 'create' | 'edit'; id?: string }>();

  return useMemo(
    () => (id ? product?.objectType : state?.objectType),
    [id, product, state]
  );
};

export const useProductFormInventoryClasses = (
  product?: ProductWithInventoryClass
) => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const owner = businessEntities.find(
    ({ id }) => id === (product?.ownerId ?? currentBusinessEntityId)
  );

  return useMemo(
    () =>
      owner?.contactType === 'CO_TAXATION_GROUP'
        ? product?.inventoryClasses?.map(({ id }) => id)
        : product?.inventoryClasses?.[0].id,
    [owner?.contactType, product?.inventoryClasses]
  );
};
