import { FC, useEffect } from 'react';
import { Accordion, AccordionPanel } from '@fleet/shared';
import { Typography } from '@mui/material';
import { TransSubtitle, TransSubtitleKeys } from 'i18n/trans/subtitle';
import { useDispatch, useSelector } from 'store/utils';
import { currentTariffSelector } from 'features/tariff/tariffSelectors';
import { TariffTimeDependentConditions } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/TariffTimeDependentConditions';
import {
  getTariffInventoryParameters,
  getTariffPriceRules,
  getTariffSalesRules,
  getTariffSupplements,
  getTariffTimeDependentConditions,
} from 'features/tariff/tariffActions';
import { TariffSupplements } from 'routes/tariff/tariffFormAccordion/supplements/TariffSupplements';
import { TariffPriceRules } from 'routes/tariff/tariffFormAccordion/TariffPriceRules';
import { TariffSalesChannelsAndRetailers } from 'routes/tariff/tariffFormAccordion/TariffSalesChannelsAndRetailers';
import { TariffInventoryModel } from 'routes/tariff/tariffFormAccordion/TariffInventoryModel';

interface TariffFormAccordionProps {}

const panels: Array<[TransSubtitleKeys, FC]> = [
  ['coefficients', TariffPriceRules],
  ['inventoryModel', TariffInventoryModel],
  // ['passengerGroup', TariffPassengerGroup],
  ['timeDependentConditions', TariffTimeDependentConditions],
  ['salesChannelsAndRetailers', TariffSalesChannelsAndRetailers],
  ['supplements', TariffSupplements],
];

export const TariffFormAccordion: FC<TariffFormAccordionProps> = () => {
  const dispatch = useDispatch();
  const tariff = useSelector(currentTariffSelector);

  useEffect(() => {
    if (tariff) {
      dispatch(getTariffTimeDependentConditions(tariff.id));
      dispatch(getTariffSupplements(tariff.id));
      // dispatch(getTariffPassengerGroup(tariff.id));
      dispatch(getTariffPriceRules(tariff.id));
      dispatch(getTariffSalesRules(tariff.id));
      dispatch(getTariffInventoryParameters(tariff.id));
    }
  }, [tariff, dispatch]);

  if (!tariff) {
    return null;
  }

  return (
    <Accordion controlled={false}>
      {panels.map(([key, Component]) => {
        return (
          <AccordionPanel
            key={key}
            id={key}
            summary={
              <Typography variant="subtitle">
                <TransSubtitle i18nKey={key} />
              </Typography>
            }
            disabled={!Component}
          >
            {Component ? <Component /> : <span />}
          </AccordionPanel>
        );
      })}
    </Accordion>
  );
};
