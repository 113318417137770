import { Pagination } from '@fleet/shared/dto/pagination';
import { createReducer } from '@reduxjs/toolkit';
import {
  Product,
  ProductCategoryMap,
  ProductsFilter,
  ProductWithAccordionInformation,
} from 'dto/product';
import {
  clearProducts,
  getProduct,
  getProductCategories,
  getProductCategoryMapping,
  getProducts,
  setCurrentProduct,
  setProductsFilter,
  updateBundledProducts,
} from 'features/product/productActions';
import { ProductCategory } from 'dto/productCategory';

export interface ProductState {
  filter: Partial<ProductsFilter>;
  list?: Pagination<Product>;
  current?: ProductWithAccordionInformation<Product>;
  categories: Array<ProductCategory>;
  categoryMapping: Array<ProductCategoryMap>;
}

const initialState: ProductState = {
  filter: {},
  categories: [],
  categoryMapping: [],
};

export const productReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setProductsFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(clearProducts, (state) => {
      state.list = undefined;
    })
    .addCase(getProducts.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(getProductCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    })
    .addCase(getProductCategoryMapping.fulfilled, (state, action) => {
      state.categoryMapping = action.payload;
    })
    .addCase(getProduct.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(updateBundledProducts.fulfilled, (state, action) => {
      state.current!.bundledProducts = action.payload;
    })
    .addCase(getProduct.rejected, (state) => {
      state.current = undefined;
    })
    .addCase(setCurrentProduct, (state, action) => {
      state.current = action.payload
        ? {
            ...state.current,
            ...action.payload,
          }
        : undefined;
    });
});
