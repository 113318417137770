import {
  Button,
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  TableDragDropProps,
  useDraggable,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
  useRowExpandToggle,
} from '@fleet/shared';
import { CardContent, Stack } from '@mui/material';
import { AncillaryTypeSubtype } from 'dto/ancillaryTypes';
import {
  deleteAncillaryTypeSubtypes,
  getAncillaryTypeSubtypes,
  reorderAncillaryTypeSubtypes,
  updateOrCreateAncillaryTypeSubtypes,
} from 'features/ancillaryTypes/ancillaryTypesActions';
import { ancillaryTypeSelector } from 'features/ancillaryTypes/ancillaryTypesSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useExpanded, useRowSelect } from 'react-table';
import { AncillaryTypesLocalizations } from 'routes/ancillaryTypes/Accordion/AncillaryTypesLocalizations';
import { useDispatch, useSelector } from 'store/utils';

interface AncillaryTypesSubtypesProps {}

export const AncillaryTypesSubtypes: FC<AncillaryTypesSubtypesProps> = () => {
  const { open: isOpen, onOpen, onClose } = useModal();

  const { id, subtypes = [] } = useSelector(ancillaryTypeSelector)!;
  const dispatch = useDispatch();

  const columns: TableColumns<AncillaryTypeSubtype> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '50%',
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="order" />,
        Cell: ({ value }) => value ?? '-',
      },
      {
        accessor: 'localizations',
        Header: <TransTableHead i18nKey="localizations" />,
        Cell: ({ value }) => (value && value.length) || '-',
      },
    ],
    []
  );

  const { form } = useForm<{ rows: Array<AncillaryTypeSubtype> }>({
    initialValues: {
      rows: subtypes,
    },
  });

  const table = useFormTable<AncillaryTypeSubtype>(
    {
      data: subtypes,
      columns,
      form,
      autoResetExpanded: false,
      onRowUpdate: async (payload) => {
        await dispatch(updateOrCreateAncillaryTypeSubtypes({ id, payload }));
      },
    },
    useExpanded,
    useRowSelect,
    useRowExpandToggle,
    useIndeterminateRowSelectCheckbox,
    useDraggable,
    useRowEditActions
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<AncillaryTypeSubtype>) => {
      await dispatch(
        deleteAncillaryTypeSubtypes({
          id,
          ids: rows.map(({ id }) => id).filter(Boolean),
        })
      ).unwrap();

      dispatch(getAncillaryTypeSubtypes(id));
      onClose();
    },
    [dispatch, id, onClose]
  );

  const handleDragEnd = useCallback<
    TableDragDropProps<AncillaryTypeSubtype>['onDragEnd']
  >(
    ({ data }) => {
      dispatch(reorderAncillaryTypeSubtypes({ id, subtypes: data }));
      table.toggleAllRowsExpanded(false);
    },
    [dispatch, id, table]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Button
          variant="text"
          startIcon={<Icon name="delete" />}
          onClick={onOpen}
          disabled={table.selectedFlatRows.length === 0}
          color="error"
        >
          <TransButton i18nKey="deleteSelected" />
        </Button>
        <Button
          variant="text"
          onClick={addRow}
          startIcon={<Icon name="plus" />}
        >
          <TransButton i18nKey="addNew" />
        </Button>
      </Stack>
      <Table
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        getHeaderProps={{ style: { backgroundColor: 'white' } }}
        table={table}
        getSubRow={({ original }) => (
          <CardContent>
            <AncillaryTypesLocalizations data={original} />
          </CardContent>
        )}
        getRowProps={() => ({
          sx: { backgroundColor: 'common.white' },
          onClick: () => null,
        })}
        draggable
        onDragEnd={handleDragEnd}
        emptyLabel={<TransField i18nKey="noSubtypes" />}
      />
      <ConfirmDeleteModal
        handleDelete={removeSelectedRows}
        title={<TransModal i18nKey="deleteAncillaryTypeSubtypes" />}
        description={
          <TransModal i18nKey="ancillaryTypeSubtypesDeletionDescription" />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </FormProvider>
  );
};
