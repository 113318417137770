import { Product, ProductsFilter } from 'dto/product';
import qs from 'qs';
import { ProductCategory } from 'dto/productCategory';
import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { Classifier } from '@fleet/shared/dto/classifier';
import { AftersalesRuleBase, AftersalesRuleBasePayload } from 'dto/aftersales';

export const fetchProductCategories = async (
  ownerId?: number
): Promise<Array<ProductCategory>> =>
  (
    await api.get(
      `/product-categories${qs.stringify(
        { ownerId },
        { addQueryPrefix: true, skipNulls: true }
      )}`
    )
  ).data.items;

export const fetchReservationProducts = async (
  ownerId: number | undefined
): Promise<Array<Classifier>> => {
  const products = (
    await api.get(
      `/products${qs.stringify(
        {
          ownerId,
          categoryClassificationId: 'PRODUCT_CATEGORY.RESERVATION',
          limit: 1000,
        },
        { addQueryPrefix: true, skipNulls: true }
      )}`
    )
  ).data.items;

  return products.map((item: Product) => ({
    id: item.id,
    name: item.description,
  }));
};

export const fetchProducts = async (filter: Partial<ProductsFilter>) =>
  (
    await api.get<Pagination<Product>>(
      `/products${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;

export const fetchProductAfterSalesRules = async <T extends AftersalesRuleBase>(
  rulesetId: string
) =>
  (
    await api.get<{
      items: Array<T>;
    }>(`/aftersales/rulesets/${rulesetId}/rules`)
  ).data.items;

export const deleteProductAfterSalesRule = (
  rulesetId: string,
  ruleId: string
) => api.delete(`/aftersales/rulesets/${rulesetId}/rules/${ruleId}`);

export const createOrUpdateProductAfterSalesRule = async <
  T extends AftersalesRuleBasePayload
>(
  rulesetId: string,
  payload: T
) =>
  await (payload.id ? api.put : api.post)(
    `/aftersales/rulesets/${rulesetId}/rules${
      payload.id ? `/${payload.id}` : ''
    }`,
    payload
  );
