import { AddButton, CardHeader, Layout } from '@fleet/shared';
import { FC } from 'react';
import { TransHeader } from 'i18n/trans/header';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { TariffForm } from 'routes/tariff/TariffForm';
import { TariffTable } from 'routes/tariff/TariffTable';

export const Tariff: FC = () => {
  const history = useHistory();

  return (
    <Layout
      header={
        <CardHeader title={<TransHeader i18nKey="tariff" />}>
          <AddButton onClick={() => history.push('/tariff/create')}>
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
      drawer={
        <Switch>
          <Route
            path="/tariff/:action(create|edit)/:id?"
            component={TariffForm}
            exact
          />
        </Switch>
      }
    >
      <TariffTable />
    </Layout>
  );
};
