import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  addProductsToBundle: (
    <Trans
      i18nKey="modal.title.addProductsToBundle"
      defaults="Add products to product bundle"
    />
  ),
  ancillaryTypeDeletionDescription: (
    <Trans
      i18nKey="modal.title.ancillaryTypeDeletionDescription"
      defaults="Are you sure you want to delete this ancillary type? It cannot be undone."
    />
  ),
  ancillaryTypesDeletionDescription: (
    <Trans
      i18nKey="modal.title.ancillaryTypesDeletionDescription"
      defaults="Are you sure you want to delete these ancillary types? It cannot be undone."
    />
  ),
  ancillaryTypeLocalizationsDeletionDescription: (
    <Trans
      i18nKey="modal.title.ancillaryTypeLocalizationsDeletionDescription"
      defaults="Are you sure you want to delete selected ancillary type localizations? It cannot be undone."
    />
  ),
  ancillaryTypeSubtypesDeletionDescription: (
    <Trans
      i18nKey="modal.title.ancillaryTypeSubtypesDeletionDescription"
      defaults="Are you sure you want to delete selected ancillary type subtypes? It cannot be undone."
    />
  ),
  deleteAncillaryTypes: (
    <Trans
      i18nKey="modal.title.deleteAncillaryTypes"
      defaults="Are you sure you want to delete the ancillary types?"
    />
  ),
  deleteAncillaryTypeLocalizations: (
    <Trans
      i18nKey="modal.title.deleteAncillaryTypeLocalizations"
      defaults="Delete ancillary type localizations"
    />
  ),
  deleteAncillaryTypeSubtypes: (
    <Trans
      i18nKey="modal.title.deleteAncillaryTypeSubtypes"
      defaults="Delete ancillary type subtypes"
    />
  ),
  deleteProduct: (
    <Trans
      i18nKey="modal.title.deleteProduct"
      defaults="Are you sure you want to delete the product?"
    />
  ),
  deleteTaxationRule: (
    <Trans
      i18nKey="modal.title.deleteTaxationRule"
      defaults="Are you sure you want to delete the Taxation rule?"
    />
  ),
});
