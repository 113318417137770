import {
  Button,
  FormControl,
  FormProvider,
  SearchForm,
  SelectField,
  TextField,
  formSubmit,
} from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Grid, Stack } from '@mui/material';
import { ProductCategorySearchFilter } from 'dto/productCategory';
import {
  getProductCategoryList,
  setProductCategoryFilter,
} from 'features/productCategory/productCategoryActions';
import {
  productCategoryFilterSelector,
  productCategoryMappingsSelector,
} from 'features/productCategory/productCategorySelectors';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { OwnerField } from 'components/OwnerField';

export const ProductCategorySearchForm: FC = () => {
  const dispatch = useDispatch();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(productCategoryFilterSelector);

  const onSubmit = useCallback(
    (values: Partial<ProductCategorySearchFilter>) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getProductCategoryList(values));
      }),
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId, ...filter }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setProductCategoryFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<ProductCategorySearchFilter>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setProductCategoryFilter({}));
  }, [form, dispatch]);

  const categoryMappings = useSelector(productCategoryMappingsSelector);
  const categories = categoryMappings.map(({ categoryClassification }) => ({
    value: categoryClassification.id,
    label: categoryClassification.name,
  }));

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} columns={4}>
            <Grid item xs={1}>
              <OwnerField showEmptyOption required={false} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="categoryId"
                label={<TransTitle i18nKey="group" />}
                options={categories}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="description"
                label={<TransTitle i18nKey="description" />}
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={handleReset}
                    variant="text"
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button icon="search" variant="contained" type="submit">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
