import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { CardHeader, Icon, Layout } from '@fleet/shared';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ProductDetails } from 'routes/products/ProductDetails';
import { ProductsTable } from 'routes/products/ProductsTable';
import { Dropdown } from '@fleet/shared/mui';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { productCategoriesSelector } from 'features/product/productSelector';
import { getProductCategories } from 'features/product/productActions';

const useStyles = makeStyles(
  (theme) => ({
    dropDown: {
      '& .MuiButton-endIcon, & .Icon-root': {
        color: theme.palette.text.warning,
      },
    },
    dropDownLabel: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.warning,
    },
  }),
  {
    name: 'Products',
  }
);

interface ProductsProps {}

export const Products: FC<ProductsProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const productCategories = useSelector(productCategoriesSelector);

  useEffect(() => {
    dispatch(getProductCategories(currentBusinessEntityId));
  }, [currentBusinessEntityId, dispatch]);

  return (
    <Layout
      header={
        <CardHeader title={<TransTitle i18nKey="products" />}>
          <Grid container spacing={1} rowSpacing={1}>
            <Grid item xs={1}>
              <Dropdown
                className={classes.dropDown}
                label={
                  <div className={classes.dropDownLabel}>
                    <Icon name="add" sx={{ mr: 1 }} />
                    <TransButton i18nKey="add" />
                  </div>
                }
                options={productCategories.map(
                  ({ id, description, category }) => ({
                    value: id,
                    label: description,
                    onClick: () =>
                      history.push({
                        pathname: '/products/create',
                        state: {
                          objectType: category.id,
                        },
                      }),
                  })
                )}
              />
            </Grid>
          </Grid>
        </CardHeader>
      }
      drawer={
        <Switch>
          <Route
            path="/products/:action(create|edit)/:id?"
            component={ProductDetails}
            exact
          />
        </Switch>
      }
    >
      <ProductsTable />
    </Layout>
  );
};
