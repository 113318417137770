import { FC, Fragment } from 'react';
import { DateField } from '@fleet/shared/form';
import { Button, Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { Grid, IconButton } from '@mui/material';
import { FieldArray } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';

export const TravelBlackoutDates: FC = () => (
  <FieldArray name="travelBlackoutDates">
    {({ fields }) => (
      <>
        {fields.map((name, index) => (
          <Fragment key={name}>
            <Grid item xs={1}>
              <DateField
                label={
                  <TransField
                    i18nKey="dateCount"
                    values={{ count: index + 1 }}
                  />
                }
                name={name}
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={() => fields.remove(index)} sx={{ mt: 2.5 }}>
                <Icon name="trash" size={24} />
              </IconButton>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} />
          </Fragment>
        ))}
        <Button
          variant="text"
          color="primary"
          sx={{ mt: 1 }}
          startIcon={<Icon name="plus" />}
          onClick={() => fields.push({ from: '', to: '' })}
          label={<TransButton i18nKey="addNewDate" />}
        />
      </>
    )}
  </FieldArray>
);
