import { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Loader, theme, ThemeProvider } from '@fleet/shared/mui';
import { Provider as AlertProvider } from 'react-alert';
import { alertProviderProps } from '@fleet/shared/components/Alert';
import { AuthProvider } from 'react-oidc-context';
import authConf from 'authConf';
import { Application } from 'App';
import 'i18n';
import 'styles/index.scss';
import { initializeSentry } from 'helpers/sentryConfig';
import AxiosErrorsInterceptorWrapper from 'wrappers/AxiosErrorsInterceptorWrapper';

initializeSentry();

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider {...authConf}>
            <AlertProvider {...alertProviderProps}>
              <AxiosErrorsInterceptorWrapper>
                <Application />
              </AxiosErrorsInterceptorWrapper>
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
