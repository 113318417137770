import type { Option } from '@fleet/shared/dto/option';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ConditionField,
  makeClassificationOptions,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { InventoryClassField } from 'routes/products/productFields/InventoryClassField';
import { ClassificationGroup } from 'dto/classification';
import { fetchReservationProducts } from 'features/product/productService';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import {
  Product,
  ProductAdmission,
  ProductAdmissionPayload,
  ProductObjectType,
} from 'dto/product';
import { OwnerField } from 'components/OwnerField';
import { useProductSelector } from 'features/product/productSelector';
import { ProductForm } from 'routes/products/ProductForm';
import {
  useProductFormCommonFields,
  useProductFormInventoryClasses,
  useProductFormOnSubmit,
} from 'hooks/useProductFormUtilities';
import { Classifier } from '@fleet/shared/dto/classifier';

const isProductAdmission = (product?: Product): product is ProductAdmission =>
  Boolean(product) && product?.objectType === ProductObjectType.ADMISSION;

interface AdmissionFormProps {}

export const AdmissionForm: FC<AdmissionFormProps> = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [reservationProductOptions, setReservationProductOptions] = useState<
    Array<Option<string>>
  >([]);
  const reservationOptions = useClassificationOptions(
    ClassificationGroup.RESERVATION_OPTION
  );
  const categoriesOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );
  const product = useProductSelector(isProductAdmission);
  const commonFields = useProductFormCommonFields(product);
  const onSubmit = useProductFormOnSubmit();
  const inventoryClassIds = useProductFormInventoryClasses(product);

  const initialValues: Partial<ProductAdmissionPayload> = useMemo(
    () => ({
      ...commonFields,
      ...(product && {
        ...product,
        inventoryClassIds,
        reservationOptionId: product.reservationOption?.id,
        reservationProductId: product.reservationProduct?.id,
      }),
    }),
    [commonFields, inventoryClassIds, product]
  );

  const formProps = useForm<ProductAdmissionPayload>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  const handleGetReservationProducts = useCallback(async () => {
    setReservationProductOptions(
      makeClassificationOptions<Classifier>(
        await fetchReservationProducts(currentBusinessEntityId)
      )
    );
  }, [currentBusinessEntityId]);

  const handleReservationOptionIdChange = useCallback(
    (value) =>
      value === 'RESERVATION_OPTION.NOT_AVAILABLE' &&
      formProps.form.change('reservationProductId'),
    [formProps.form]
  );

  useEffect(() => {
    handleGetReservationProducts();
  }, [handleGetReservationProducts]);

  return (
    <ProductForm formProps={formProps}>
      <Grid item xs={1}>
        <SelectField
          name="objectType"
          label={<TransField i18nKey="category" />}
          options={categoriesOptions}
          disabled
          required
        />
      </Grid>
      <Grid item xs={1}>
        <OwnerField disabled />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="description"
          label={<TransField i18nKey="description" />}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="code" label={<TransField i18nKey="code" />} required />
      </Grid>
      <Grid item xs={1}>
        <InventoryClassField />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          name="reservationOptionId"
          label={<TransField i18nKey="reservationOption" />}
          options={reservationOptions}
          onChange={handleReservationOptionIdChange}
          required
        />
      </Grid>
      <Grid item xs={1}>
        <ConditionField
          when="reservationOptionId"
          is={['RESERVATION_OPTION.MANDATORY', 'RESERVATION_OPTION.OPTIONAL']}
        >
          <SelectField
            name="reservationProductId"
            label={<TransField i18nKey="reservationProduct" />}
            options={reservationProductOptions}
            required
          />
        </ConditionField>
      </Grid>
    </ProductForm>
  );
};
