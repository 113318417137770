import { FC, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormField,
  useFormContext,
  useLocale,
} from '@fleet/shared';
import { metaError } from '@fleet/shared/form/FormField';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { TimeDependentConditions } from 'dto/tariff';

const useStyles = makeStyles(
  () => ({
    selectedWeekdays: {
      color: '#FFFFFF',
      backgroundColor: '#293845',
    },
    notSelectedWeekdays: {
      color: '#293845',
      backgroundColor: '#FFFFFF',
    },
  }),
  {
    name: 'TravelDaysOfTheWeek',
  }
);

export const TravelDaysOfTheWeek: FC = () => {
  const form = useFormContext<TimeDependentConditions>();
  const values = form.getState().values;
  const classes = useStyles();

  const { localize } = useLocale();
  const validOnDayOptions = useMemo(
    () =>
      [
        [1, 'Monday'],
        [2, 'Tuesday'],
        [3, 'Wednesday'],
        [4, 'Thursday'],
        [5, 'Friday'],
        [6, 'Saturday'],
        [0, 'Sunday'],
      ].map(([day, value]) => ({
        value: value as string,
        label: localize?.day(day, { width: 'abbreviated' }),
      })),
    [localize]
  );

  const getArrayWithAfterToggledValue = (value: string) => {
    const copyOfTravelDaysOfTheWeek = [...values.travelDaysOfTheWeek!];
    const index = copyOfTravelDaysOfTheWeek?.indexOf(value);

    if (index === -1) {
      copyOfTravelDaysOfTheWeek.push(value);
    } else {
      copyOfTravelDaysOfTheWeek.splice(index, 1);
    }

    return copyOfTravelDaysOfTheWeek;
  };

  return (
    <Grid item xs={1}>
      <FormField
        name="travelDaysOfTheWeek"
        render={({ meta }) => (
          <FormControl error={metaError(meta)}>
            <Stack direction="row">
              {validOnDayOptions.map(({ value, label }) => {
                const lowerCasedValue = value.toLowerCase();

                return (
                  <Button
                    key={value}
                    className={
                      values?.travelDaysOfTheWeek?.includes(lowerCasedValue)
                        ? classes.selectedWeekdays
                        : classes.notSelectedWeekdays
                    }
                    variant="outlined"
                    onClick={() =>
                      form.change(
                        'travelDaysOfTheWeek',
                        getArrayWithAfterToggledValue(lowerCasedValue)
                      )
                    }
                  >
                    {label}
                  </Button>
                );
              })}
            </Stack>
          </FormControl>
        )}
      ></FormField>
      <Button
        variant="text"
        color="primary"
        size="large"
        onClick={() =>
          form.change(
            'travelDaysOfTheWeek',
            validOnDayOptions.map(({ value }) => value.toLowerCase())
          )
        }
        label={<TransButton i18nKey="selectAll" />}
      />
    </Grid>
  );
};
