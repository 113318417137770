import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ProductsFilter } from 'dto/product';
import { TransField } from 'i18n/trans/field';
import {
  getProducts,
  setProductsFilter,
} from 'features/product/productActions';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import {
  Button,
  ConditionField,
  FormControl,
  FormProvider,
  formSubmit,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useDispatch, useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import type { Option } from '@fleet/shared/dto/option';
import { fetchProductCategories } from 'features/product/productService';
import { productsFilterSelector } from 'features/product/productSelector';
import { OwnerField } from 'components/OwnerField';

interface ProductsSearchFormProps {}

export const ProductsSearchForm: FC<ProductsSearchFormProps> = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [productCategoryOptions, setProductCategoryOptions] = useState<
    Array<Option<string>>
  >([]);
  const filter = useSelector(productsFilterSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: Partial<ProductsFilter>) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getProducts({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId, ...filter }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setProductsFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<ProductsFilter>({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });
  const { ownerId } = form.getState().values;

  const fetchProductCategoryOptions = useCallback(async (ownerId) => {
    setProductCategoryOptions(
      (await fetchProductCategories(ownerId)).map(({ id, category }) => ({
        value: id,
        label: category.name,
      }))
    );
  }, []);

  useEffect(() => {
    fetchProductCategoryOptions(ownerId);
  }, [fetchProductCategoryOptions, ownerId]);

  useEffect(() => {
    form.change('categoryId');
  }, [form, ownerId]);

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setProductsFilter({}));
  }, [form, dispatch]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <OwnerField showEmptyOption required={false} />
            </Grid>
            <Grid item xs={1}>
              <TextField name="code" label={<TransField i18nKey="code" />} />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="description"
                label={<TransField i18nKey="description" />}
              />
            </Grid>
            <Grid item xs={1}>
              <ConditionField when="ownerId" is={Boolean}>
                {(ownerId) => (
                  <SelectField
                    name="categoryId"
                    label={<TransField i18nKey="category" />}
                    options={productCategoryOptions}
                    disabled={!ownerId}
                    showEmptyOption
                  />
                )}
              </ConditionField>
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button icon="search" type="submit">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
