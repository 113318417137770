import { FC, useCallback, useMemo } from 'react';
import {
  FormProvider,
  Icon,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { TimeDependentConditions } from 'dto/tariff';

import { Row, useExpanded, useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentTariffSelector,
  tariffTimeDependentConditionsSelector,
} from 'features/tariff/tariffSelectors';
import { tariffTimeDependentConditionsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  createOrUpdateTariffTimeDependentConditions,
  deleteTariffTimeDependentConditions,
  getTariffTimeDependentConditions,
} from 'features/tariff/tariffActions';
import { Box, Button, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TimeDependentCondition } from 'routes/tariff/tariffFormAccordion/timeDependentConditions/TimeDependentCondition';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { formatDate } from '@fleet/shared/utils/date';
import { TransTableHead } from 'i18n/trans/table';

export const TariffTimeDependentConditions: FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(tariffTimeDependentConditionsSelector);
  const loading = useSelector(tariffTimeDependentConditionsLoadingSelector);
  const tariffId = useSelector(currentTariffSelector)?.id;
  const alert = useAlert();

  const columns: TableColumns<TimeDependentConditions> = useMemo(
    () => [
      {
        id: 'expander',
        width: 10,
        Cell: ({ row }: { row: Row<TimeDependentConditions> }) =>
          row.state.editable ? null : (
            <Icon
              {...row.getToggleRowExpandedProps()}
              name={`chevron-${row.isExpanded ? 'down' : 'right'} `}
            />
          ),
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
      {
        id: 'salesDates.from',
        accessor: ({ salesDates }) =>
          salesDates?.from ? formatDate(salesDates.from) : '',
        Header: <TransTableHead i18nKey="salesDateFrom" />,
        type: 'date',
        editableProps: {
          required: false,
        },
      },
      {
        id: 'salesDates.to',
        accessor: ({ salesDates }) =>
          salesDates?.to ? formatDate(salesDates.to) : '',
        Header: <TransTableHead i18nKey="salesDateTo" />,
        type: 'date',
        editableProps: {
          dateType: 'end',
          required: false,
        },
      },
    ],
    []
  );

  const { form } = useForm<{ rows: Array<TimeDependentConditions> }>({
    initialValues: {
      rows: data,
    },
  });

  const onRowUpdate = useCallback(
    async (values) => {
      await dispatch(
        createOrUpdateTariffTimeDependentConditions({
          ...values,
          travelPeriodTypeId: 'TRAVEL_PERIOD_TYPE.FIXED',
        })
      ).unwrap();
      await dispatch(getTariffTimeDependentConditions(tariffId!));
      alert.success(<TransAlert i18nKey="timeDependantConditionsUpdated" />);
    },
    [alert, dispatch, tariffId]
  );

  const table = useFormTable(
    {
      data,
      columns,
      form,
      autoResetExpanded: false,
      onRowUpdate,
    },
    useExpanded,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: TimeDependentConditions[]) => {
      await dispatch(deleteTariffTimeDependentConditions(payload));
      tariffId && (await dispatch(getTariffTimeDependentConditions(tariffId)));
    },
    [dispatch, tariffId]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Button
              variant="text"
              color="error"
              onClick={removeSelectedRows}
              startIcon={<Icon name="delete" />}
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              variant="text"
              onClick={addRow}
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            getHeaderGroupProps={() => ({
              sx: {
                background: 'white',
              },
            })}
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
                background: 'white',
              },
            }}
            getRowProps={() => ({
              sx: {
                backgroundColor: '#F6F6F6',
              },
            })}
            getSubRow={({ original }) =>
              original.id ? (
                <TimeDependentCondition data={original} tariffId={tariffId} />
              ) : (
                <div />
              )
            }
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
